/**
Added by - Ashish Dewangan on 21-01-2024
Reason - To have styling file for external libraries
**/
.react-multiple-carousel__arrow {
  background-color: #f4bf8e !important;
  box-shadow: white 0px 1px 4px, white 0px 0px 0px 3px !important;
  min-width: 30px !important;
  min-height: 30px !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(1%) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(1%) !important;
}
.react-multiple-carousel__arrow::before {
  color: black !important;
  font-size: 15px !important;
  font-weight: bold !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: white !important;
  box-shadow: black 0px 1px 4px, black 0px 0px 0px 3px !important;
}

/**
Added by - Ashish Dewangan on 02-02-2024
Reason - To make arrow size small on responsive
**/
@media screen and (max-width: 720px) {
  .react-multiple-carousel__arrow {
    min-width: 20px !important;
    min-height: 20px !important;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 11px !important;
  }
}
/**
End of code addition by - Ashish Dewangan on 02-02-2024
Reason - To make arrow size small on responsive
**/
