/**
 * Created by - Ashish Dewangan on 18-01-2024
 * Reason - To have styling of vertical line
 */

.verticalLine {
  width: var(--vertical-line-thickness);
  background-color: var(--vertical-line-background-color);
  min-height: 100%;
}
