/**
 * Created by - Ashish Dewangan on 13-01-2024
 * Reason - To have styling of images container section
 */

.horizontalLine {
  height: var(--horizontal-line-thickness);
  background-color: var(--horizontal-line-background-color);
  border: none;
  margin-bottom: var(--horizontal-line-bottom-margin);
}
.subTitle {
  font-size: var(--page-subTitle-font-size);
  color: var(--page-subTitle-font-color);
  font-weight: var(--page-subTitle-font-weight);
  text-align: var(--page-subTitle-text-align);
}
.imagesContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  place-content: center;
}
.image {
  width: 290px;
  height: 200px;
  border-radius: 3px;
  margin: 15px;
  margin-bottom: 0px;
  margin-top: 0px;
}
/*
Added by - Ashish Dewangan on 14-01-2024
Reason - To design download icon
*/
.downloadIcon {
  color: black;
  background-color: white;
  width: 15px;
  height: 15px;
  display: block;
  position: relative;
  top: -195px;
  left: 285px;
}
/*
End of code addition by - Ashish Dewangan on 14-01-2024
Reason - To design download icon
*/
.extraBottomMargin {
  margin-bottom: 15px;
}

@media screen and (max-width: 720px) {
  .imagesContainer {
    justify-content: center;
  }

  /**
  Added by - Ashish Dewangan on 14-01-2024
  Reason - Made image and download icon responsive
  */

  .image {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 320px;
  }
  .downloadIcon {
    top: -195px;
    left: 315px;
  }
  /**
  End of code addition by - Ashish Dewangan on 14-01-2024
  Reason - Made image and download icon responsive
  */
}
