/**
Added by - Ashish Dewangan on 18-01-2024
Reason - To have styling of contact us details
*/
.contactUsDetailsContainer {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.contentTitle {
  font-size: var(--page-content-title-font-size) !important;
  color: var(--page-content-title-font-color) !important;
  font-weight: var(--page-content-title-font-weight) !important;
  text-align: var(--page-content-title-text-align) !important;
}
.paragraph {
  /**
  Modified by - Ashish Dewangan on 02-02-2024
  Reason - To remove extra padding
  **/
  /* margin-bottom: var(--bottom-margin-between-contents); */
  margin-bottom: 10px;
  /**
  End of modification by - Ashish Dewangan on 02-02-2024
  Reason - To remove extra padding
  **/

  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
}
.noContentFoundText {
  margin-bottom: 15px;
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: left;
}

@media screen and (max-width: 720px) {
  .contactUsDetailsContainer {
    width: 100%;
  }
  /*
  Added by - Ashish Dewangan on 22-01-2024
  Reason - To hide section on responsive
  */
  .hideOnResponsive {
    display: none;
  }
  /*
  End of code addition by - Ashish Dewangan on 22-01-2024
  Reason - To hide section on responsive
  */
}
