/**
Added by - Ashish Dewangan on 24-01-2024
Reason - To have styling of jyot flow component
*/
.contactUsDetailsContainer {
  width: 35%;
  /* width: 20%; */
  /* border: 1px solid black; */


  display: flex;
  flex-direction: column;
}
.image {
  /**
    Code changed by - Ashlekh on 01-04-2024
    Reason - need to decrease width so that scroller will remove from Jyot section
  **/
  /* width: 23vw; */
  width: 20vw;
  /**
    End of code - Ashlekh on 01-04-2024
    Reason - need to decrease width so that scroller will remove from jyot section
  **/
  height: auto;
}

/* Addition by Om Shrivastava on 27-01-24
Reason : When the lappi view then this div is not show */
.parentCollapse{
    display: none;
    padding-top: 3%;
}
.imageContainer{
    display: block;
}
/* End of addition by Om Shrivastava on 27-01-24
Reason : When the lappi view then this div is not show */
.contentTitle {
  font-size: var(--page-content-title-font-size) !important;
  color: var(--page-content-title-font-color) !important;
  font-weight: var(--page-content-title-font-weight) !important;
  text-align: var(--page-content-title-text-align) !important;
}
.paragraph {
  margin-bottom: var(--bottom-margin-between-contents);
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
}
.noContentFoundText {
  margin-bottom: 15px;
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: left;
}

@media screen and (max-width: 720px) {
  .contactUsDetailsContainer {
    width: 100%;
  }

  .hideOnResponsive {
    display: none;
  }
  /* Addition by Om Shrivastava on 27-01-24
Reason : When the lappi view then this div is not show */
.parentCollapse{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0%;
    
}

.imageContainer{
    display: none;
}
.image{
  /* Modification and addition by Om Shrivastava on 03-02-24
  Reason Need to decrease the width  */
    /* width: 108vw; */
    width: 100vw;
/* End of modification and addition by Om Shrivastava on 03-02-24
  Reason Need to decrease the width  */
}

/* End of addition by Om Shrivastava on 27-01-24
Reason : When the lappi view then this div is not show */
}
