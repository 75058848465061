/**
 * Created by - Ashish Dewangan on 24-01-2024
 * Reason - To have styling of jyot form
 */
.formContainer {
  width: 55%;
  /* Code changed by - Ashlekh on 10-02-2024
  Reason - To handle form when scanner image is decreased */
  /* width: 80%; */
  width: 100%;
  /* End of code - Ashlekh on 10-02-2024
  Reason - To handle form when scanner image is decreased */

  /* width: 30%; */
  /* border: 1px solid aqua; */
}
.inputHolder {
  display: flex;
  /* Code changed by - Ashlekh on 15-02-2024
  Reason - To align labels in center */
  /* align-items: flex-start; */
  align-items: center;
  /* End of code - Ashlekh on 15-02-2024
  Reason - To align labels in center */
  flex-wrap: wrap;
  width: 100%;
  margin: 15px;

  margin-left: 4%;
}
/* Addition by Om Shrivastava on 04-02-24
Reason : Need to add classname for the rupee and rupee in word section */
.inputHolderamount{
/* margin: 15px 15px 0px 15px ; */
margin: 15px 15px 0px 31px ;

}
.inputHolderamountInWord{
  /* margin: 7px 15px 15px 15px ; */
margin: 7px 15px 15px 31px ;


}
/*End of addition by Om Shrivastava on 04-02-24
Reason : Need to add classname for the rupee and rupee in word section */
.label {
  font-size: var(--form-label-font-size);
  color: var(--form-label-font-color);
  font-weight: var(--form-label-font-weight);
  /* Code changed by - Ashlekh on 10-02-2024 */
  /* width: 20%; */
  width: 13%;
  /* End of code - Ashlekh on 10-02-2024 */
}
/* Added by - Ashlekh on 10-02-2024 */
.colon{
  width: 2%;
}
/* End of code - Ashlekh on 10-02-2024 */
.radioWidth1{
  width: 10%;
}
.radioWidth2{
  width: 11.5%;
}
.radioWidth3{
  width: 13%;
}
.inputWithError {
  width: 60%;
  width: 70%;
  display: flex;
  flex-direction: column;
}
.inputBox {
  /* Code changed by - Ashlekh on 12-03-2024
  Reason - To decrease spacing in input box */
  /* padding: 5px 10px; */
  padding: 5px 5px;
  /* End of code - Ashlekh on 12-03-2024
  Reason - To decrease spacing in input box */
  outline: none;
}
.textArea {
  resize: none;
}
.buttonContainer {
  display: flex;
  /* padding-left: 20%; */
}
.button {
  /* margin-left: 15px; */
  padding: 5px 10px;
  background-color: brown;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  /* Modification and addition by Om Shrivastava on 01-02-24
  Reason : Need to decrease the width */
  /* width: 30%; */
  /* width: 15%; */
  /* width: 30%; */

  /* End of Modification and addition by Om Shrivastava on 01-02-24
  Reason : Need to decrease the width */

}
.button:hover {
  background-color: rgb(185, 75, 75);
}
.mapContainer {
  margin-top: 15px;
  width: 100%;
  height: 350px;
}
 .dateContainer{
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.validationMsgContainer{
  font-size: var(--page-validation-font-size);
  padding-left: var(--page-validation-padding-left);

  color: red;
}
/* Added by - Ashlekh on 08-02-2024
Reason - To decrease width of age input box */
.ageInputBox {
  /* Code changed by - Ashlekh on 12-03-2024
  Reason - To decrease space from age input box */
  padding: 5px 5px;
  /* End of code - Ashlekh on 12-03-2024
  Reason - To decrease space from age input box   */
  width: 13%;
  outline: none;
}
/* End of code by - Ashlekh on 08-02-2024
Reason - To decrease width of age input box */

/* Addition by Om Shrivastava on 18-05-2024
Reason : Add the designing part for description section  */
.descriptionContainer {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: red;
  padding: 1% 1% 2% 1%;
}
/* End of addition by Om Shrivastava on 18-05-2024
Reason : Add the designing part for description section  */
@media screen and (max-width: 720px) {
  .formContainer {
    width: 100%;
  }
  .inputWithError {
    width: 70%;
    width: 74.4%;
  }
  .label {
    /* Code changed by - Ashlekh on 10-02-2024 
       Reason - To decrease width in mobile view */
    /* width: 25%; */
    width: 23%;
    /* End of code - Ashlekh on 10-02-2024 
       Reason - To decrease width in mobile view */
  }
  /* Added by - Ashlekh on 10-02-2024
     Reason -  For styling of colon in mobile view */
  .colon{
    width: 2%;
  }
  /* End of code - Ashlekh on 10-02-2024
     Reason - For styling of colon in mobile view */
  .inputHolder {
    margin-left: 0px;
    margin-right: 0px;
  }
  .buttonContainer {
    padding: 0;
    justify-content: center;
  }
  .mapContainer {
    height: 200px;
  }
  .dateContainer{
    flex-direction: column;
  }
  .radioWidth1{
    width: 21%;
  }
  .radioWidth2{
    width: 21%;
  }
  .radioWidth3{
    width: 18%;
  }
  /* Addition by Om Shrivastava 01-02-24
  Reason : Need to add addition by Om Shrivastava on 01-02-24 */
  .button{
    width: 23%;
  }
   /* End of addition by Om Shrivastava 01-02-24
  Reason : Need to add addition by Om Shrivastava on 01-02-24 */
  /* Addition by Om shrivastava on 03-02-24
  Reason : Need to resize the radio button in mobile view  */
    input[type='radio'] {
      -webkit-transform: scale(0.7); /* Safari and Chrome */
      -moz-transform: scale(0.7); /* Firefox */
      -ms-transform: scale(0.7); /* IE 9 */
      -o-transform: scale(0.7); /* Opera */
      transform: scale(0.7); /* General */
    }
    input[type='radio']:checked {
      -webkit-transform: scale(0.7); /* Safari and Chrome */
      -moz-transform: scale(0.7); /* Firefox */
      -ms-transform: scale(0.7); /* IE 9 */
      -o-transform: scale(0.7); /* Opera */
      transform: scale(0.7); /* General */
    }
  
    /* End of addition by Om shrivastava on 03-02-24
  Reason : Need to resize the radio button in mobile view  */
  /**
    * Added by - Ashlekh on 08-02-2024
    * Reason - To handle age input field in mobile view
    */
  .ageInputBox {
    width: 50px;
  }
  /**
    * End of code addition by - Ashlekh on 08-02-2024
    * Reason - To handle age input field in mobile view
    */
}
@media screen and (max-width: 360px) {
  .inputWithError {
    width: 75%;
    /* border: 1px solid red; */
  }
}
