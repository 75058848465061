/**
 * Created by - Ashlekh on 26-01-2024
 * Reason - For styling in receipt section
 */

/**
 Added by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/
.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}
/**
 End of addition by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  /* padding-bottom: 10px; */
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
  margin-bottom: var(--page-title-margin-bottom);
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
}

.subTitleText {
  text-align: center;
  /* font-weight: bold; */
  font-size: 16px;
  padding-bottom: 10px;
}
/* .inputMobileNumber {
  text-align: center;
  font-weight: bold;
} */

.inputHolder {
  display: flex;
  /* align-items: flex-start; */
  /* flex-wrap: wrap; */
  /* width: 50%; */
  /* margin: 15px; */
  justify-content: center;
  /* align-items: center; */
  /* border: 1px solid red; */
  align-items: baseline;
}
.label {
  /* font-size: var(--form-label-font-size); */
  font-size: 14px;
  color: var(--form-label-font-color);
  /* font-weight: var(--form-label-font-weight); */
  font-weight: bold;
  width: 8%;
}
.inputWithError {
  /* width: 60%; */
  width: 15%;
  display: flex;
  flex-direction: column;
}
.inputBox {
  /* Code changed by - Ashlekh on 12-03-2024
  Reason - To decrease gap */
  /* padding: 3px 10px; */
  padding: 3px 5px;
  /* End of code - Ashlekh on 12-03-2024
  Reason - To decrease gap */
  outline: none;
}

.buttonContainer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: var(--bottom-margin-between-contents);
}

.buttonSubContainer1 {
  width: 8%;
  /* border: 1px solid black; */
}

.button {
  padding: 5px 10px;
  background-color: brown;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 14px;
}
.button:hover {
  background-color: rgb(185, 75, 75);
}

.tableContainer {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* border: 1px solid black; */
  /* width: 800px; */
}

/* .tableHeader {
  display: flex;
  font-weight: bold;
  text-align: center;
} */

.tableBody {
  display: flex;
  flex-direction: column;
}

.tableRow {
  display: flex;
  /* gap: 20px; */
}

.tableButton {
  text-decoration: none;
  color: black;
  background-color: #f8e9ad;
  padding: 0.1px 2px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid black;
  display: inline-block;
  text-align: center;
}

.tableHeader {
  border: 1px solid black;
  text-align: center;
  font-size: 18px;
}

.tableHeading {
  border: 1px solid black;
  text-align: center;
  font-size: 18px;
}
.validationMsgContainer{
  font-size: var(--page-validation-font-size);
  padding-left: var(--page-validation-padding-left);


  color: red;
}

@media screen and (max-width: 720px) {
  .tableContainer {
    width: 100%;
    overflow-x: scroll !important;
  }
  .inputHolder {
    width: 80%;
    justify-content: space-evenly;
    /* align-items: center;  */
    gap: 10px;
  }

  .label {
    width: auto;
  }

  .inputWithError {
    width: 60%;
  }

  .buttonContainer {
    /* width: 100%;  */
  }

  .button {
    /* width: 100%;  */
  }
  /* Added by - Ashlekh on 12-03-2024
  Reason - To handle gap in mobile view */
  .inputBox {
    padding: 3px 5px;
    outline: none;
  }
  /* End of code - Ashlekh on 12-03-2024
  Reason - To handle gap in mobile view */
}
