/**
 * Created by - Ashish Dewangan on 13-01-2024
 * Reason - To have styling live video container section
 */
.horizontalLine {
  height: var(--horizontal-line-thickness);
  background-color: var(--horizontal-line-background-color);
  border: none;
  margin-bottom: var(--horizontal-line-bottom-margin);
}

.subTitle {
  font-size: var(--page-subTitle-font-size);
  color: var(--page-subTitle-font-color);
  font-weight: var(--page-subTitle-font-weight);
  text-align: var(--page-subTitle-text-align);
}

.liveVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 15px; */
}
.video {
  width: 500px;
  height: 300px;
  margin: 15px;
}
/* Addition by Om Shrivastava on 10-02-24
Reason : Need to fix the width  */
.liveVideoImage{
  width: 3.7vw;
}
/* End of addition by Om Shrivastava on 10-02-24
Reason : Need to fix the width  */

/* Addition by Om Shrivastava on 08-02-24
Reason : Need to set the blink property of the div */
.blinkDiv {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* End of addition by Om Shrivastava on 08-02-24
Reason : Need to set the blink property of the div */
@media screen and (max-width: 720px) {
  .video {
    width: 100%;
    height: auto;
  }
  /* Addition by Om Shrivastava on 10-02-24
Reason : Need to fix the width  */
.liveVideoImage{
  width: 12vw;
}
/* End of addition by Om Shrivastava on 10-02-24
Reason : Need to fix the width  */
}
