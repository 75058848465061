/**
 * Created by - Om Shrivastava on 14-02-24
 * Reason - To have styling of jyot page
 */

.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  /* Commented by Om Shrivastava on 19-02-24
  Reason : Need to comment this code, beacause when donation description data is not added, then scroller is show */
  /* padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom); */
  /* End of commented by Om Shrivastava on 19-02-24
  Reason : Need to comment this code, beacause when donation description data is not added, then scroller is show */
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading {
  margin: auto;
}
.currentTimeData {
  align-self: flex-end;
  font-size: 12px;
  padding-bottom: 0.5%;
}
.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);
}
/* Addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
.loaderDiv{
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);
}
/* End of addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */

.section {
  display: flex;
  justify-content: space-around;
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}

@media screen and (max-width: 720px) {
  .section {
    flex-direction: column;
  }
  .title {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .currentTimeData {
    margin-bottom: 1%;
    margin-left: auto;
  }
  .heading {
    margin-left: auto auto auto 3%;
    position: absolute;
  }
/* Addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
.loaderDiv{
  min-height: 85vh;
}
/* End of addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
}
