/**
  * Created by - Ashlekh on 10-01-2024
  * Reason - For styling of footer section
  */

/* body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
} */

.footerContainer {
  /* height: 200px; */
  /* width: 98vw; */
  /* border: 2px solid black; */
  /* align-items: start; */
  /* width: 100%; */
  height: 8vh;
  height: 7vh;

  align-items: center;
  display: flex;
  justify-content: space-around;
  /* background: linear-gradient(to right, #FFDE59, #FF914D); */
  background: #f4bf8e;
  /* padding-left: 15px; */
  /* padding-left: 15px; */

  /* position: fixed; */
  left: 0;
  bottom: 0;
  /* width: 100%; */

  /**
Added by - Ashish Dewangan on 01-02-2024
Reason - To add background image on footer
**/
  background-image: url("../../../public/footer.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /**
End of code addition by - Ashish Dewangan on 01-02-2024
Reason - To add background image on footer
**/
}

.footerSection1 {
  display: flex;
  gap: 6px;
  flex-direction: column;
  /* font-size: 11px; */
  font-size: 13px;

  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.footerSection1Text {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.element {
  /* Modification and addition by Om Shrivastava on 30-03-24
  Reason : Need to align the content */
  /* margin-top: 15px; */
  margin-top: 2px;
  /* End of modification and addition by Om Shrivastava on 30-03-24
  Reason : Need to align the content */
}

.footerSection2 {
  display: flex;
  /* flex-direction: column; */
  gap: 6px;
  flex-direction: row;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
}

.footerSection2Text {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
}

.footerSection3 {
  display: flex;
  gap: 20px;
  /* height: 20px;
    padding-top: 6vh;  
    justify-content: center; */
}

/* .footerSection3Text {
    font-weight: bold;
    text-align: center;
} */
.footerSection4 {
  display: flex;
  flex-direction: column;
  /* Modification and addition by Om Shrivastava on 30-03-24
  Reason : Need to set the height of the footer section  */
  /* height: 75%; */
  height: 57%;
   /* Modification and addition by Om Shrivastava on 30-03-24
  Reason : Need to set the height of the footer section  */
  width: 20%;
  align-items: center;
}

.subscribe {
  background-color: #fff !important;
  height: 42px;
  /* height: 90%; */
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: relative;
  border-radius: 10px;
  /* border: 1px solid black; */
  margin: 1%;
}

.emailLogo {
  width: 22px;
  align-self: center;
}

.email.inputBtn {
  height: 100%;
  outline: none;
  border: none;
  width: 75%;
  background-color: #fff !important;
}

.footerSection5 {
  display: flex;
}

.footerSection5Text {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.footerLink {
  text-decoration: none;
  color: inherit;

  font-size: 12px;
  font-size: 14px;
}

.logo {
  /* width: 100%; */
  height: 3vh;
  /* filter: blur(1px); */
}

.combined {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: space-between;
  justify-content: space-around;
  /* gap: 40px; */
}

.responsiveCopyRight {
  display: none;
}

/* .socialLinks{
    display: flex;
    justify-content: space-evenly;
} */
/* .link{
    margin: 0 10px;
} */

.submitBtn {
  border: none;
  cursor: pointer;
}

.submitBtn:hover {
  opacity: 0.8;
}

.inputBtn {
  color: white;
  color: black;

  font-size: 17px;
  font-weight: 500;
}

.inputBtn:focus {
  outline: 2px solid white;
}

.inputBtn::placeholder {
  color: rgba(255, 255, 255, 0.6);
  color: rgba(50, 50, 50, 0.411);
  font-size: 12px;
}

/* .submitBtn on top of the .inputBtn */
.wrapper {
  /* width: 400px; */
  width: 280px;

  max-width: 100%;
  position: relative;
}

.wrapper .inputBtn {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid white;
  border-radius: 50px;
  /* padding: 1rem 2rem; */
  padding: 2px 2px 2px 2px;
  background-color: white;
  font-size: 12px;
  width: 100%;
  height: 38px;
  /* margin-top: 2%; */
  /* height: 25px;
  width: 100%;
  font-size: 12px; */
}
.wrapper .inputBtn2 {
  height: 25px;
  width: 80%;
  font-size: 12px;
  width: 100%;
  border: 2px solid white;
  border-radius: 50px;
  padding-left:5px;
}

.wrapper .submitBtn {
  /* background: black; */
  border-radius: 50px;
  aspect-ratio: 1;
  height: 80%;
  /* height: 60%; */

  position: absolute;
  top: 0%;
  right: 3px;
  margin-top: 1%;
  height: 50%;
  background-color: unset;

}
.wrapper .submitBtn2 {
  /* background: black; */
  border-radius: 50px;
  aspect-ratio: 1;
  height: 80%;
  /* height: 60%; */

  position: absolute;
  top: 2%;
  right: 3px;
  margin-top: 1%;
  height: 80%;
  border: none;
  background-color: unset;
}

.emailBtnn{
  margin-top: -50%;
  font-size: 15px;
}

/* Added by - Ashlekh on 02-02-2024
Reason - For styling in whatsapp icon */
.whatsAppIcon{
  position: absolute;
  bottom: 10px;
}
/* End of code addition by - Ashlekh on 02-02-2024
Reason - For styling in whatsapp icon */

@media screen and (max-width: 720px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    /* margin-top: 1px; */
    height: 22vh;
    height: 17vh;
  }

  .combined {
    order: 0;
    width: 80%;
    display: flex;
    /* Modification and addition by Om Shrivastava on 31-01-24
    Reason : Fix the footer bug (Number : 15)*/
    /* flex-direction: column; */
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* Code changed by - Ashlekh on 09-02-2024 */
    /* height: 28px; */
    height: 14px;
    /* End of code - Ashlekh on 09-02-2024 */
  }

  .footerSection4 {
    order: 1;
    width: 80%;
    /* padding-top: 2%; */
    height: 30%;
    /* margin-left: -4%; */
  }
  .wrapper .inputBtn {
    /* border: 1px solid aqua; */
    /* width: 50%; */
  }
  .footerSection1 {
    display: none;
  }
  .wrapper {
    width: 350px;
  }
  .wrapper .inputBtn2 {
    padding: 2px 2px 2px 12px;
    background-color: white;
    font-size: 12px;
    width: 100%;
    height: 38px;
  }
  .wrapper .submitBtn {
    /* background: oran; */
    border-radius: 50px;
    aspect-ratio: 1;
    height: 80%;
    /* height: 60%; */

    position: absolute;
    top: 2%;
    right: 3px;
    margin-top: 1%;
    height: 60%;
  }
  .footerSection1Text {
    display: none;
  }

  /* .footerSection1{
        order: 3;
        align-self: flex-start;
    } */
  .footerSection5 {
    order: 3;
    /* width: 48%; */
    /* Modification and addition by Om Shrivastava on 22-01-24
    Reason : Need to add end property */
    /* align-self: flex-end; */
    /* align-self: end; */
    /* End of Modification and addition by Om Shrivastava on 22-01-24
    Reason : Need to add end property */

    /* width: 88%; */
    /* Addition by Om Shrivastava on 22-01-24
    Reason : Set the responsiveness property for the footer section */
    /* Code changed by - Ashlekh on 09-02-2024 */
    /* width: 100%; */
    width: 95%;
    /* End of code by - Ashlekh on 09-02-2024 */
    display: flex;
    /* Code changed by - Ashlekh on 09-02-2024 */
    /* justify-content: space-around; */
    justify-content: space-between;
    /* End of code by - Ashlekh on 09-02-2024 */
    /* Addition by Om Shrivastava on 22-01-24
    Reason : Set the responsiveness property for the footer section */

    /* Addition by Om Shrivastava on 22-01-24
    Reason : Set the responsiveness property for the footer section */
    gap: 40px;
    /* Addition by Om Shrivastava on 22-01-24
    Reason : Set the responsiveness property for the footer section */
  }

  .responsiveCopyRight {
    display: flex;
    /* order: 3; */
    align-self: flex-start;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
  }
  .footerSection2 {
    padding-left: 3%;
    padding-top: 2%;
  }
  .footerSection3 {
    /* Code changed by - Ashlekh on 09-02-2024
    Reason - To fix footer social media links */
    /* padding-right: 2%; */
    padding-right: 3%;
    padding-bottom: 2%;
    /* End of code - Ashlekh on 09-02-2024
    Reason - To fix footer social media links */
  }
.element{
  margin-top: 1%;
}
.footerLink {
  /* Code changed by - Ashlekh on 09-02-2024
  Reason - to decrease size */
  /* font-size: 12px; */
  font-size: 11px;
  /* End of code - Ashlekh on 09-02-2024
  Reason - To decrease size */
}
.footerSection5Text {
  /* Code changed by - Ashlekh on 09-02-2024 */
  /* font-size: 12px; */
  font-size: 11px;
  font-weight: bold;
  /* End of code by - Ashlekh on 09-02-2024 */
}
.emailBtnn{
    /* margin-top: -25%; */
    margin-top: -14%;
margin-top: 1%;
    font-size: 20px;
  }
  
.whatsAppIcon{
  /* position: right; */
  /* position: fixed; */
}
/* Added by - Ashlekh on 09-02-2024
Reason - To decrease size of social media */
.logo{
  height: 2.5vh;
}
/* End of code by - Ashlekh on 09-02-2024
Reason - To decrease size of social media  */
.element2 {
  margin-top: 12px;
}

}
