.container {
  /* margin-bottom: 30px; */
  /**
  Modified by - Ashish Dewangan on 29-01-2024
  Reason - To have margin bottom globally
  **/
  /* margin-bottom: 3%; */
  margin-bottom: var(--bottom-margin-between-contents);
  /**
  End of modification by - Ashish Dewangan on 29-01-2024
  Reason - To have margin bottom globally
  **/
  /* margin-left: 1.4%; */
  margin-left: 2.8%;
  margin-right: 2.8%;
}
.mandirMembersContainer {
  /* justify-content: center; */
  /* margin-top: 10%; */
  /* Code commented by - Ashlekh on 03-02-2024 */
  /* display: flex;
  border-radius: 8px !important; */
  /* End of comment by - Ashlekh on 03-02-2024 */
  /* border: 1px solid black; */
  /* box-sizing: border-box; */
  /* justify-content: space-around; */
  /* Code commented by - Ashlekh on 03-02-2024 */
  /* align-items: center; */
  /* End of comment by - Ashlekh on 03-02-2024 */
  /* height: 46.5vh; */
  /* margin-bottom: 10%; */
}

.mandirMembersSubContainer {
  height: 335px;
  width: 338px;
  /* width: 290px; */

  /* width: 310px; */
  border-radius: 5%;
  background-color: #f6ccaa;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* border: 1px solid black; */
  /* margin-bottom: 20px; */
}
/* Code added by - Ashlekh on 03-02-2024
Reason - To change colour by even & odd */
.even {
  background-color: #f0d9dd;
}
.odd {
  /* Modification and addition by Om Shrivastava on 08-02-24
  Reason : Need to set the same background-color */
  /* background-color: rgba(154, 3, 30, 0.1490196078); */
  background-color: #e3dbe0;
  /* End of modification and addition by Om Shrivastava on 08-02-24
  Reason : Need to set the same background-color */
}
/* End of code addition by - Ashlekh on 03-02-2024
Reason - To change colour by even & odd */

.memberImage {
  /* margin-left: 55px; */
  /* margin-top: 20px; */
  /* margin-left: 17%; */
  width: 170px;
  height: 170px;
  border-radius: 50% 50%;
  align-self: center;
  padding-bottom: 3px;
  /* border: 1px solid black; */
}
/* .memberImage:hover{
  border: 2px solid red;
  outline: 2px solid black;
} */
.memberName {
  /* margin-top: 20px; */
  font-weight: bold;
  text-align: center;
  /* word-wrap: break-word; */
   /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
}

.memberDesignation {
  text-align: center;
  /* word-wrap: break-word; */
   /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
  font-weight: bold;
}

.memberNameAndDesignation {
  text-align: center;
  font-weight: bold;
  /* border: 1px solid blue; */
  /* Code added by - Ashlekh on 03-02-2024
  Reason - To fix name and description */
  /* min-height: 50px; */
  min-height: 35px;

  /* min-width: 70%; */
  /* width: 90%; */
   /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
  word-wrap: break-word;
  padding-left: 12%;
  padding-right: 12%;
  margin-bottom: 0.1rem;
  font-size: 15px;
  /* End of code addition by - Ashlekh on 03-02-2024
  Reason - To fix name and description */
}

.memberImage:hover {
  /* outline: 10px solid red; */
}
@media screen and (max-width: 720px) {
  /* Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property  */
  .container {
    margin-left: 0;
  }
  /* End of Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property */
  /* Addition by Om Shrivastava on 03-02-24
Reason : Need to set the width */
  .mandirMembersSubContainer {
    /* Code commented and added by - Ashlekh on 04-02-2024 
  Reason - To set height and width*/
    /* width: 500px; */
    /* width: 373px;
  height: 350px;*/
    width: 360px;
    height: 340px;

    margin-left: 2%;

    /* End of comment and code addition by - Ashlekh on 04-02-2024
  Reason - To set height and width*/
  }
  /* End of addition by Om Shrivastava on 03-02-24
Reason : Need to set the width   */
}
/* Addition by Om Shrivastava on 08-02-24
Reason : Need to add the media query in 360px view  */
@media screen and (max-width: 360px) {
  .mandirMembersSubContainer {
    width: 333px;
    height: 340px;
    margin-left: 0%;
  }
}
/* End of addition by Om Shrivastava on 08-02-24
Reason : Need to add the media query in 360px view  */
