/**
  * Created by - Ashlekh on 10-01-2024
  * Reason - To have styling for header section
  */

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.mainHeaderContainer {
  /* height: 50px; */
  /* border: 2px solid black; */
  /* height: 10vh; */
  height: 7vh;
  /* background: linear-gradient(to right, #ffde59, #ff914d); */
  /* background: linear-gradient(to right, #f0e78c, #febe10); */
  /* Code added by - Ashlekh on 31-01-2024
    Reason - To set header */
  background-image: url("../../../public/header4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* End of code addition by - Ashlekh on 31-01-2024
    Reason - To set header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  /* width: 100svw; */
  width: 100vw;
  top: 0;
  /* background-color: gainsboro; */
  /**
      Added by - Ashish Dewangan on 14-01-2024
      Reason - To make header appear in front of other elements
      */
  z-index: 9999;
  /**
      End of code addition by - Ashish Dewangan on 14-01-2024
      Reason - To make header appear in front of other elements
      */
}

.logoContainer {
  /* width: 20svw; */
  /* padding-left: 20px; */
  height: 10vh;
}

/* .logoContainer2{
      width: 100px; 
      height: 100%;
  } */

.navigationContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  /* margin-left: auto;
      margin-right: auto; */
}

.navigation {
  display: flex;
  gap: 20px;
  /* gap: auto; */
  font-weight: bold;
  color: rgb(48, 25, 8);
  /*font-size: 26px */
  font-size: 20px;
  text-decoration: none;
}
/* Added by - Ashlekh on 25-05-2024
Reason - To have background color when we click on any option of header */
.active{
  /* background-color: rgb(218, 99, 15); */
  background-color: white;

  /* padding-left: 3px;
  padding-right: 3px; */
  padding: 0px 5px 0px 5px;
  border-radius: 8%;
}
/* End of code - Ashlekh on 25-05-2024
Reason - To have background color when we click on any option of hedaer */

.logoContainer {
  /* width: 5vw; */
  align-self: flex-start;
}

.logo {
  /* width: 10hvh; */
  /* height: 10sv; */
  /* filter: blur(1px); */
  /* height: 10vh; */
  /**
      Modified by - Ashish Dewangan on 30-01-2024
      Reason - To design logo on responsive
      **/
  /* heigh: 6vh; */
  /* Addition by Om Shrivastava on 05-04-24
    Reason : Set the padding for the logo  */
  padding: 4.9%;
  /* End of addition by Om Shrivastava on 05-04-24
    Reason : Set the padding for the logo  */
  height: 14vh;
  /* background-color: rgb(249, 191, 82); */
  /* border-radius: 0% 0% 50% 50%; */
  /* border: 1px solid lightgray; */
  /**
      End of modification by - Ashish Dewangan on 30-01-2024
      Reason - To design logo on responsive
      **/
}

.button {
  display: none;
}

.drawerContainer {
  height: 100%;
  width: 100%;
  background-color: var(--color-white);
}

.drwHeader {
  height: 10vh;
  width: 100%;
  align-items: flex-start;
  color: var(--color-prime);
}

.drawerMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

/* .navigation:hover {
  color: rgb(248, 234, 220);
} */
/* Added by - Ashlekh on 25-01-2024
     Reason - To fix Spacing between modules*/
.navigationSubContainer1 {
  display: flex;
  flex-direction: row;
  /* Code changed by - Ashlekh on 29-03-2024
    Reason - To fix width */
  /* width: 33%; */
  width: 40%;
  /* End of code - Ashlekh on 29-03-2024
    Reason - To fix width */
  justify-content: space-between;
  /* padding-right: 5%; */
  padding-left: 3.9%;
}
.navigationSubContainer2 {
  display: flex;
  flex-direction: row;
  /* Code changed by - Ashlekh on 29-03-2024
    Reason - To fix width and gap */
  /* width: 20%; */
  /* gap: 30px; */
  width: 100%;
  gap: 26px;
  /* End of code - Ashlekh on 29-03-2024
    Reason - To fix width and gap */
  justify-content: space-around;
}
/* End of code addition by - Ashlekh on 25-01-2024
     Reason -  To fix spacing between modules*/

.subContainer1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Modification and addition by Om Shrivastava on 27-03-24
    Reason : Need to set the width  */
  /* width: 100%; */
  /* Code changed by - Ashlekh on 29-03-2024
    Reason - To fix width */
  /* width: 86.4%; */
  width: 79%;
  /* End of code - Ashlekh on 29-03-2024
    Reason - To fix width */
  /* End of modification and addition by Om Shrivastava on 27-03-24
    Reason : Need to set the width  */
}

/* .subContainer2{
    display: flex;
    flex-direction: row;
  } */

/* Added by - Ashlekh on 05-02-2024
  Reason - For styling in horizontal line */
.horizontalLine {
  /* color: black;
    margin: 0.5rem 0; */
  /* Modification and addition by Om Shrivastava on 09-02-24
    Reason : Need to set the color  */
  /* color: var(--horizontal-line-drawer-color); */
  color: rgb(242, 98, 41);
  /* End of modification and addition by Om Shrivastava on 09-02-24
    Reason : Need to set the color  */

  margin: var(--horizontal-line-drawer-margin);
  padding: 1px;
  margin: 1px;
}
/* End of comment by - Ashlekh on 05-02-2024
  Reason - For styling in horizontal line */

@media screen and (max-width: 960px) {
  .mainHeaderContainer {
    display: flex;
    height: 6vh;
  }

  .navigationContainer {
    display: none;
  }

  .logo {
    /* width: 9svh; */
    /**
      Modified by - Ashish Dewangan on 30-01-2024
      Reason - To design logo on responsive
      **/
    /* height: 6vh; */
    height: 12vh;
    /**
      End of modification by - Ashish Dewangan on 30-01-2024
      Reason - To design logo on responsive
      **/
  }

  .button {
    display: block;
    /* margin-right: 30px; */
    height: 30px;
    width: 30px;
    /* background-color: white; */
  }

  .iconContainer {
    height: 32px;
    width: 50px;
  }

  .show {
    display: flex;
  }

  .hide {
    display: none;
  }

  .hamburgerDrawer {
    width: 50%;
  }
  .link {
    text-decoration: none;
    /* Added by - Ashlekh on 03-02-2024 */
    color: #a21f3b;
    /* End of code addition by - Ashlekh on 03-02-2024 */
    padding-left: 3%;
    padding-top: 2%;
    font-size: 15px;
  }
  .navigationSubContainer1 {
    display: none;
  }
  .navigationSubContainer2 {
    display: none;
  }
  /* Addition by Om Shrivastava on 09-02-24
    Reason : Need to add the flex property in 960 view */
  .li {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
  }
  /* End of addition by Om Shrivastava on 09-02-24
    Reason : Need to add the flex property in 960 view */
}

@media screen and (max-width: 720px) {
  .mainHeaderContainer {
    display: flex;
    /* height: 9vh; */
    height: 6vh;
  }

  .navigationContainer {
    display: none;
  }

  .button {
    display: block;
    /* margin-right: 30px; */
    height: 30px;
    width: 30px;
    /* background-color: white; */
  }

  .iconContainer {
    height: 32px;
    width: 50px;
  }

  .show {
    display: flex;
  }

  .hide {
    display: none;
  }

  .logo {
    /* height: 9vh; */
    /* margin-left: 20px; */
    /* padding-left: 10px; */
    /**
      Modified by - Ashish Dewangan on 30-01-2024
      Reason - To design logo on responsive
      **/
    /* height: 6vh; */
     /* Addition by Om Shrivastava on 05-04-24
    Reason : Set the padding for the logo  */
  /* padding: 9% 0% 0% 0%;
  margin-right: 13%; */
  /* End of addition by Om Shrivastava on 05-04-24
    Reason : Set the padding for the logo  */
    /* Code changed by - Ashlekh on 05-04-2024
    Reason - To fix header logo size */
    /* height: 12vh;
    border-radius: 0% 50% 50% 0%; */
    height: 13vh;
    margin-left: -12%;
    padding-top: 8px;
    /* End of code - Ashlekh on 05-04-2024
    Reason - To fix header logo size */
    /**
      End of modification by - Ashish Dewangan on 30-01-2024
      Reason - To design logo on responsive
      **/
  }

  .li {
    /* Code commented by - Ashlekh on 03-02-2024
      Reason - To remove spacing */
    /* margin-bottom: 4.7vh; */
    /* End of comment by - Ashlekh on 03-02-2024
      Reason - To remove spacing */
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    padding-bottom: 3px;
    /* border: 1px solid black; */
  }

  .hamburgerDrawer {
    width: 50%;
  }

  .ant-drawer-body {
    width: 100%;
    /* background-color: red !important; */
  }

  .ant-drawer-wrapper-body {
    background-color: yellow !important;
  }

  .drawerMenu {
    list-style: none;
    text-decoration: none;
  }
}
/* Addition by Om Shrivastava on 25-01-24
  Reason : Set the header height  */
@media screen and (max-width: 720px) {
  .mainHeaderContainer {
    display: flex;
    height: 7vh;
  }
}
/* End of addition by Om Shrivastava on 25-01-24
  Reason : Set the header height  */
