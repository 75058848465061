/**
 * Created by - Ashish Dewangan on 12-01-2024
 * Reason - To have styling of about gods page
 */

/**
 Added by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/
.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}
/**
 End of addition by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  /* Commented by Om Shrivastava on 22-01-24
  Reason : Remove the padding top */
  /* padding-top: var(--page-margin-top); */
  /* End of Commented by Om Shrivastava on 22-01-24
  Reason : Remove the padding top  */
  padding-bottom: var(--page-margin-bottom);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  min-height: var(--page-min-height);

  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
  margin-bottom: var(--page-title-margin-bottom);
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
}

.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);

  margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right);
}
/* Addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
.loaderDiv{
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);
  margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right);
}
/* End of addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}

/* Added by - Ashlekh on 14-01-2024 */
.marqueeText {
  behavior: scroll;
  direction: left;
  color: rgb(0, 0, 0);
  /* margin-top: 20px; */
  /* margin-top: 5px;
  font-size: 100%; */
}

.godsPhotoContainer {
  /* height: 30vh;
    padding: 2% 4% 2%; */
  padding: 1% 4% 1%;
  /* background-color: #fafafa; */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  /* border: 1px solid black; */
  justify-content: space-between;
  /* width: 100%; */
}

.godsPhotoSubContainer {
  /* height: auto; */
  /* max-height: 100%; */

  /* min-width: 8.5%; */
  margin-right: 0.75%;
  box-sizing: border-box;
  cursor: pointer;
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
  /* background-color: rgb(235, 18, 18); */
  /* 
    display: inline-block;
    vertical-align: top; */
}
.godImage {
  width: 120px;
  height: 150px;
  background-size: cover;
}

/* .godsPhotoSubContainer img {
  height: 10vh;
} */

.godName {
  text-align: center;
}

.coverImageContainer {
  /* width: 100%; */
  height: 100%;
  margin: 1% 0;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}

.sliderContainer {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.imageDiv {
  /* margin: auto; */
  height: 52vh;
  display: flex;
}

/* .image{
  width: auto;
  height: 100%;
} */

.e-carousel .e-carousel-indicators .e-indicator-bars .e-indicator-bar {
  padding: 8px;
}

.photoAndContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.sliderContent {
  margin-top: 2%;
  border-radius: 20px;
  overflow: hidden;
  width: 50%;
}

.contentContainer {
  width: 50%;
  padding-top: 2%;
  padding-left: 2%;
   /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
}

.noContentFoundText {
}

.mandirMembersContainer {
  justify-content: center;
  margin-top: 1px;
  display: flex;
  border-radius: 8px !important;
}
/* Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property  */
.marqueeContainer {
  padding-top: 7px;
  font-size: 14px;
}
/* End of Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property */
/* Add any additional styling as needed */

/* End of code addition by - Ashlekh on 14-01-2024 */

@media screen and (max-width: 720px) {
  /* Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property  */
  .marqueeContainer {
    padding-top: 0px;
    padding-left: 2%;
  }
  /* Addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
.loaderDiv {
  min-height: 85vh;
}
/* End of addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
  /* End of Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property */
}
