/*
Added by - Ashish Dewangan on 12-01-2024
Reason - To specify global css styling
*/
@import url("externalLibrariesStyles.css");

:root {
  --page-margin-left: 30px;
  --page-margin-right: 30px;
  /* Modification and addition by Om Shrivastava on 25-01-24
  Reason : Set the margin top functionality */
  /* --page-margin-top: 10vh; */
  --page-margin-top: 7vh;
  /* End of Modification and addition by Om Shrivastava on 25-01-24
  Reason : Set the margin top functionality  */
  --page-margin-bottom: 0;
  --page-padding-top: 0.4vh;
  --page-padding-bottom: 2vh;
  --page-min-height: 86vh;

  --page-title-font-size: 22.5px;
  --page-title-font-color: black;
  --page-title-font-weight: bold;
  --page-title-text-align: center;
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin on title
  **/
  --page-title-margin-bottom: 10px;
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin on title
  **/

  /**
  Added by - Ashish Dewangan on 01-02-2024
  Reason - To have background image on all pages globally
  **/
  --page-background-image: url("../public/mandala_art_bg.png");
  --page-background-color: rgb(255, 254, 253, 0.7);
  /**
  End of code addition by - Ashish Dewangan on 01-02-2024
  Reason - To have background image on all pages globally
  **/

  --page-subTitle-font-size: 18px;
  --page-subTitle-font-color: black;
  --page-subTitle-font-weight: bold;
  --page-subTitle-text-align: left;

  /*
  Added by - Ashish Dewangan on 18-01-2024
  Reason - To have global styling for content title and subtitle
  */
  --page-content-title-font-size: 18px;
  --page-content-title-font-color: black;
  --page-content-title-font-weight: bold;
  --page-content-title-text-align: left;

  --page-content-subTitle-font-size: 16px;
  --page-content-subTitle-font-color: black;
  --page-content-subTitle-font-weight: bold;
  --page-content-subTitle-text-align: left;

  --bottom-margin-between-contents: 30px;
  /*
  End of code addition by - Ashish Dewangan on 18-01-2024
  Reason - To have global styling for content title and subtitle
  */

  --page-content-font-size: 14px;
  --page-content-font-color: black;
  --page-content-font-weight: 400;
  --page-content-text-align: left;
  --page-content-margin-left: 70px;
  --page-content-margin-right: 70px;

  --page-content-not-found-margin-top: 1rem;
  --page-content-not-found-margin-bottom: 0rem;
  --page-content-not-found-text-align: center;
  --page-content-not-found-font-size: 12px;
  --page-content-not-fount-font-color: black;
  --page-content-not-found-font-weight: 400;
  /* Addition by Om Shrivastav on 08-02-24
REason : Need to set the error font size globally */
  --page-validation-font-size: 12px;
  /* Code changed by - Ashlekh on 12-03-2024
  Reason - To decrease gap for validation message */
  /* --page-validation-padding-left:11px; */
  --page-validation-padding-left:6px;
  /* End of code - Ashlekh on 12-03-2024
  Reason - To decrease gap for validation message */

  /* End of addition by Om Shrivastav on 08-02-24
REason : Need to set the error font size globally  */

  /**
  Added by - Ashish Dewangan on 18-01-2024
  Reason - To add style for form labels
  */
  /* Modidfication and addition by Om Shrivastava on 27-01-24
  Reason : Need to decrese the font size  */
  /* --form-label-font-size: 14px; */
  --form-label-font-size: 13px;
  /* End of Modidfication and addition by Om Shrivastava on 27-01-24
  Reason : Need to decrese the font size  */

  --form-label-font-color: black;
  --form-label-font-weight: bold;
  /**
  End of code addition by - Ashish Dewangan on 18-01-2024
  Reason - To add style for form labels
  */

  --horizontal-line-thickness: 2px;
  --horizontal-line-background-color: #e6e6e8;
  --horizontal-line-bottom-margin: 5px;

  --vertical-line-thickness: 2px;
  --vertical-line-background-color: #e6e6e8;

  /**
    * Added by - Ashlekh on 05-02-2024
    * Reason - For styling in drawer
    */
  --horizontal-line-drawer-color: black;
  --horizontal-line-drawer-margin: 0.5rem 0;
  /**
    * End of code addition by - Ashlekh on 05-02-2024
    * Reason - For styling in drawer
    */
}

.Collapsible__trigger {
  background-color: #ffc107;
  padding: 1%;
  font-size: 15px;
  font-weight: bold;
}
/* Addition by Om Shrivastava on 24-02-24
Reason : Need to remove the arrow increasing, decreasing icon */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
/* End of addition by Om Shrivastava on 24-02-24
Reason : Need to remove the arrow increasing, decreasing icon */

@media screen and (max-width: 720px) {
  :root {
    /*
    Added by - Ashish Dewangan on 14-01-2024
    Reason - Added responsive view
    */
    --page-margin-left: 10px;
    --page-margin-right: 10px;
    --page-min-height: 76vh;
    --page-content-margin-left: 0px;
    --page-content-margin-right: 0px;

    --page-margin-top: 7vh;
    --page-margin-bottom: 0;

    --horizontal-line-thickness: 2px;
    --horizontal-line-background-color: #e6e6e8;
    /*
    End of code addition by - Ashish Dewangan on 14-01-2024
    Reason - Added responsive view
    */
  }
}

/*
End of code addition by - Ashish Dewangan on 12-01-2024
Reason - To specify global css styling
*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* Addition by Om Shrivastava on 10-02-24
Reason : Need to remove the scroll functionality */
:where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-content{
  overflow: hidden;
  border-radius: 3% !important;
}
/* End of Addition by Om Shrivastava on 10-02-24
Reason : Need to remove the scroll functionality  */

 /* Addition by Om Shrivastava on 10-02-24
  Reason : Need to remove the scroller  */
:where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-body {
  overflow: hidden;
}
/* End of Addition by Om Shrivastava on 10-02-24
  Reason : Need to remove the scroller */

/* Added by - Ashlekh on 13-01-2024
   Reason - For styling of hamburger in header  */
@media screen and (max-width: 720px) {
  .ant-drawer-header {
    background-color: #f2f2f6 !important;
    border-radius: 0 !important;
    width: 50vw;
  }
  .ant-drawer-content-wrapper {
    /*Code changed by - Ashlekh on 03-02-2024 */
    /* width: 70vw !important; */
    /* Code changed by - Ashlekh on 29-03-2024
    Reason - To fix width */
    /* width: 55vw !important; */
    width: 57vw !important;
    /* End of code - Ashlekh on 29-03-2024
    Reason - To fix width */
    /* Code changed by - Ashlekh on 15-02-2024
    Reason - To fix drawer height */
    /* height: 48vh !important; */
    height: 56vh;
    /* End of code - Ashlekh on 15-02-2024
    Reason - To fix drawer height */
  }
  /* Added by Om Shrivastava on 09-02-24
  Reason : Need to remove the scroller in mobile view  */
  :where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-content{
    overflow: hidden;
    border-radius: 3% !important;

  }
  :where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-body {
    padding: 15px;
    /* Addition by Om Shrivastava on 10-02-24
    Reason : Need to remove the scroller  */
    overflow: hidden;
    /* End of Addition by Om Shrivastava on 10-02-24
    Reason : Need to remove the scroller */
  }
  /* End of Added by Om Shrivastava on 09-02-24
  Reason : Need to remove the scroller in mobile view */
  .ant-drawer-title {
    color: #fff;
    width: 50vw;
  }

  .ant-drawer-body {
    background-color: #eeedf4;
    color: #fff;
    height: calc(100vh - 55px);
    font-size: 3vh;
    /* text-align: center; */
    text-align: left;
    /* width: calc(100vw -55px); */
    /* Code changed by - Ashlekh on 15-02-2024 */
    /*width: 70vw; */
    width: 56.5vw;
    /* End of code - Ashlekh on 15-02-2024 */
  }

  /* Addition by Om Shrivastava on 02-02-24
  Reason : Need to set the width of notification  */
  .notyf__toast {
    width: auto !important;
  }
  /* End of addition by Om Shrivastava on 02-02-24
  Reason : Need to set the width of notification  */
}
/* End of code addition by - Ashlekh on 13-01-2024
   Reason - For styling of hamburger in header */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Addition by om Shrivastava on 08-02-24
Reason : Need to fix the whatsapp icon  */
@media screen and (max-width: 720px) {
  .styles-module_whatsappButton__tVits {
    margin-bottom: 17% !important;
  }
}
/* End of Addition by om Shrivastava on 08-02-24
Reason : Need to fix the whatsapp icon  */

/* Added by - Ashlekh on 16-02-2024
Reason - Need to fix drawer in responsive */
@media screen and (max-width: 394px) {
  .ant-drawer-content-wrapper {
    /* Code changed by - Ashlekh on 29-03-2024
    Reason - To fix drawer height */
    /* height: 58vh; */
    /* height: 62vh; */
    height: 64vh;
    /* End of code - Ashlekh on 29-03-2024
    Reason - To fix drawer height */
  }
}
/* End of code - Ashlekh on 16-02-2024
Reason - Need to fix drawer in responsive */
