/**
  * Created by - Ashlekh on 18-01-2024
  * Reason - For styling in cover image
  */
.coverImageContainer {
  /* width: 100%; */
  height: 100%;
  margin: 1% 0;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}

.sliderContainer {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.imageDiv {
  /* margin: auto; */
  height: 52vh;
  display: flex;
}
/* Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property  */
.image {
  height: 50vh;
  width: 100%;
}

@media screen and (max-width: 720px) {
  .image {
    /* height: 25vh; */
    height: 20vh;
    width: 100%;
  }
}
/* End of Addition by Om Shrivastava on 22-01-24
Reason : Set the padding top property */
