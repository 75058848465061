/**
  * Created by - Ashlekh on 18-01-2024
  * Reason - For designing of home page photo
  */

.photoAndContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  /* Added by - Ashlekh on 27-01-2024 
  Reason - To give gap in bottom after homepage photo and content */
  /**
  Modified by - Ashish Dewangan on 29-01-2024
  Reason - To have margin bottom between contents globally
  **/
  /* padding-bottom: 4%; */
  margin-bottom: var(--bottom-margin-between-contents);
  /**
  End of modification by - Ashish Dewangan on 29-01-2024
  Reason - To have margin bottom between contents globally
  **/
  /* End of code addition by - Ashlekh on 27-01-2024
  Reason - To give gap in bottom after homepage photo and content */
}

.sliderContent {
  margin-top: 2%;
  border-radius: 20px;
  overflow: hidden;
  width: 50%;
}

.contentContainer {
  width: 50%;
  padding-top: 2%;
  padding-left: 2%;
   /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
  font-size: var(--page-content-font-size);
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Addition by Om Shrivastava on 24-01-24
Reason : Set the padding top property  */
.image {
  height: 50vh;
  width: 100%;
}
.dataNotPresent {
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 14px;
}

@media screen and (max-width: 720px) {
  .image {
    height: 25vh;
    width: 100%;
  }
  .photoAndContentContainer {
    flex-direction: column;
  }
  .sliderContent {
    width: 100%;
  }
  .contentContainer {
    width: 100%;
  }
  .dataNotPresent {
    height: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    padding-top: 15%;
  }
  .noContentFoundText {
    /* Code modified by - Ashlekh on 04-02-2024
       Reason - For not displaying text in responsive */
    /* height: 2vh; */
    display: none;
    /* End of modification by - Ashlekh on 04-02-2024
       Reason - For not displaying text in responsive */
  }
}
/* End of Addition by Om Shrivastava on 24-01-24
Reason : Set the padding top property */
