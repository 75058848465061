/**
  * Created by - Ashlekh on 29-03-2024
  * Reason - To style donation receipt history
  */

.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  margin-bottom: var(--page-title-margin-bottom);
}

.subTitleText {
  text-align: center;
  font-size: 16px;
  padding-bottom: 10px;
}
.inputHolder {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.label {
  font-size: 14px;
  color: var(--form-label-font-color);
  font-weight: bold;
  width: 8%;
}
.inputWithError {
  width: 15%;
  display: flex;
  flex-direction: column;
}
.inputBox {
  padding: 3px 5px;
  outline: none;
}

.buttonContainer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: var(--bottom-margin-between-contents);
}

.buttonSubContainer1 {
  width: 8%;
}

.button {
  padding: 5px 10px;
  background-color: brown;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 14px;
}
.button:hover {
  background-color: rgb(185, 75, 75);
}

.validationMsgContainer {
  font-size: var(--page-validation-font-size);
  padding-left: var(--page-validation-padding-left);

  color: red;
}

.button {
    padding: 5px 10px;
    background-color: brown;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 14px;
  }
  .button:hover {
    background-color: rgb(185, 75, 75);
  }
  
  .tableContainer {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* border: 1px solid black; */
    /* width: 800px; */
  }
  
  /* .tableHeader {
    display: flex;
    font-weight: bold;
    text-align: center;
  } */
  
  .tableBody {
    display: flex;
    flex-direction: column;
  }
  
  .tableRow {
    display: flex;
    /* gap: 20px; */
  }
  
  .tableButton {
    text-decoration: none;
    color: black;
    background-color: #f8e9ad;
    padding: 0.1px 2px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid black;
    display: inline-block;
    text-align: center;
  }
  
  .tableHeader {
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
  }
  
  .tableHeading {
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
  }

@media screen and (max-width: 720px) {
  .tableContainer {
    width: 100%;
    overflow-x: scroll !important;
  }
  .inputHolder {
    width: 80%;
    justify-content: space-evenly;
    gap: 10px;
  }

  .label {
    width: auto;
  }

  .inputWithError {
    width: 60%;
  }

  .buttonContainer {
  }

  .button {
  }
  .inputBox {
    padding: 3px 5px;
    outline: none;
  }
}
