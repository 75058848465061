/**
 * Created by - Ashish Dewangan on 27-01-2024
 * Reason - To have styling of jyot receipt page
 */

/**
 Added by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/
.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}
/**
 End of addition by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
  margin-bottom: var(--page-title-margin-bottom);
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
  display: flex;
  justify-content: center;
}

.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);

  margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right);
  display: flex;
  justify-content: center;
}
.borderedContainer {
  border: 1px solid black;
  display: flex;
  flex-direction: column;

  width: 30%;

  /**
  Added by - Ashish Dewangan on 01-02-2024
  Reason - To provide background image on receipt as watermark
  **/
  background-image: url("../../../public/watermark_5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /**
  End of code addition by - Ashish Dewangan on 01-02-2024
  Reason - To provide background image on receipt as watermark
  **/
}

/**
  Added by - Ashish Dewangan on 01-02-2024
  Reason - To provide background image on receipt as watermark
  **/
.watermarkBackground {
  padding: 15px;
  background-color: rgba(256, 256, 256, 0.9);
}
/**
  End of code addition by - Ashish Dewangan on 01-02-2024
  Reason - To provide background image on receipt as watermark
  **/

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.label {
  font-size: var(--form-label-font-size);
  color: var(--form-label-font-color);
  font-weight: var(--form-label-font-weight);
  width: 30%;
}
.value {
  font-size: var(--form-label-font-size);
  color: var(--form-label-font-color);
  width: 70%;
  /* border-bottom: 1px solid rgb(88, 81, 81); */
  margin-bottom: 10px;
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}
.buttonContainer {
  display: flex;
  padding-top: 1%;
  justify-content: space-around;
  width: 20%;
}
.button {
  padding: 5px 10px;
  background-color: brown;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 14px;
}
/* Addition by Om Shrivastava on 04-02-24
  Reason : Need to set the width  */
.dateField {
  width: 35%;
}
/* End of addition by Om Shrivastava on 04-02-24
  Reason : Need to set the width  */
.donation_button {
  width: fit-content;
  height: 100%;
  /* padding: 5px 30px; */
  padding: 2px 2px 2px 2px;
  border-radius: 8px;
  font-weight: 700;
  background-color: #fbfbfb;
  border: 2px solid rgb(246, 214, 30);
  color: black;
  text-align: left;
  cursor: none;
}

/* .donation_button:hover{
      background-color: rgb(246, 214, 30);
      border:2px solid black;
      color: black;
    } */
/* Added by Om Shrivastava on 10-02-24s
    Reason : Need to add the css for the receipt modules */
.receiptContainer {
  border: 1px solid black;
  margin-left: 24%;
  margin-right: 24%;
  background-color: rgb(252 205 160 / 49%);
  border-radius: 2%;
  /* padding: 10px; */

  /* background-image: url("../../../public/watermark_5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */

  /* overflow: auto; */
}
.heading {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.receiptAddress {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  padding-right: 13%;
}
.receiptContact {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  padding-right: 13%;

}
.parvData {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  padding-right: 13%;

}
.receiptFirstRowContainer {
  display: flex;
  align-items: center;
}
.receiptImgContainer {
  width: 15%;
}
.receiptImg {
  width: 7vw;
  padding-bottom: 20%;
}
.adminDetailsContainer {
  width: 85%;
  justify-content: center;
}
.receiptSecondRowContainer {
}
.receiptThirdRowContainer {
  display: flex;
  padding: 4px;
}
.receiptDataContainer {
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
}
.receiptFirstColumnDataContainer {
  display: flex;
  width: 100%;
}
.receiptFirstColumnData {
  width: 25%;
  font-weight: bold;
  font-size: 13px;
}
.receiptSecondColumnData {
  width: 8%;
  font-weight: bold;
}
.receiptThirdColumnData {
  border-bottom: 2px dotted black;
  width: 60%;
  font-size: 13px;
}
.receiptThirdColumnBtn {
  border: none;
}
.receiptSignatureFirstColumnData {
  width: 18%;
  font-weight: bold;
  font-size: 13px;
}
.receiptSignatureSecondColumnData {
  width: 4%;
  font-weight: bold;
}
.receiptSignatureThirdColumnData {
  border-bottom: 2px dotted black;
  width: 75%;
  font-size: 13px;
}
.receiptAddressFirstColumnData {
  width: 12.4%;
  font-weight: bold;
  font-size: 13px;
}
.receiptAddressSecondColumnData {
  width: 4%;
  font-weight: bold;
}
.receiptAddressThirdColumnData {
  width: 80%;
  border-bottom: 2px dotted black;
  font-size: 13px;
}
.receiptSecondColumnDataContainer {
  display: flex;
  width: 100%;
  
}
.receiptHeadingImg{
  width: 30vw;
  padding-right: 15%;
}
.headingDateConatainer{
  display: flex;
  align-items: center;
  margin-left: 15%;
  
}
.textHeading{
  display: none;
}

/* End of addition by Om Shrivastava on 10-02-24s
    Reason : Need to add the css for the receipt modules */

@media screen and (max-width: 1200px) {
  .receiptContainer {
    overflow: auto;
    margin: 0% 2% 0% 2%;
  }
  .receiptDataContainer{
    width: 150vw;
  }
  .receiptFirstRowContainer{
    /* border: 2px solid red; */
    width: 150vw;
  }
  .receiptImg {
    width: 25vw;
    padding-bottom: 60%;
  }
  .receiptHeadingImg{
    width: 90vw;
    /* border: 1px solid green; */
  }
  .receiptSignatureFirstColumnData{
    width: 33%;
  }
  .receiptSignatureThirdColumnData{
    width: 80vw;
  }
  .receiptFirstColumnData{
    width: 30%;
  }
  .receiptAddressFirstColumnData{
    width: 15%;
  }

}

@media screen and (max-width: 720px) {
  .borderedContainer {
    width: 100%;
  }
  .buttonContainer {
    padding: 0;
    justify-content: space-around;
    padding-top: 2%;
    width: 100%;
  }
  .button {
    width: 30%;
  }
  /* Addition by Om Shrivastava on 04-02-24
  Reason : Need to set the width  */
  .dateField {
    width: 32%;
  }
  /* End of addition by Om Shrivastava on 04-02-24
  Reason : Need to set the width  */
  
  /* Addition by Om Shrivastava on 28-06-2024
  Reason : Set the div and text  */
  .downloadButton{
    display:none;
  }
  .textHeading{
    display: block;
  }
   /* End of addition by Om Shrivastava on 28-06-2024
  Reason : Set the div and text  */
}
