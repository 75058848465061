/**
 * Created by - Ashish Dewangan on 12-01-2024
 * Reason - To have styling of about gods page
 */

/**
 Added by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/
.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}
/**
 End of addition by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);

  /**
  Added by - Ashish Dewangan on 14-01-2024
  Reason - To apply global padding for page
  */
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  /**
  End of code addition by - Ashish Dewangan on 14-01-2024
  Reason - To apply global padding for page
  */

  min-height: var(--page-min-height);

  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
  margin-bottom: var(--page-title-margin-bottom);
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
}

.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);

  margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right);
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}

.detailsContainer {
  display: flex;
  justify-content: space-around;
}
.imageSection {
  width: 20%;
}
.image {
  /*
  Modified by - Ashish Dewangan on 22-01-2024
  Reason - To adjust size of image
  */
  /* width: 264px;
  height: 340px; */
  /*
  End of code modification by - Ashish Dewangan on 22-01-2024
  Reason - To adjust size of image
  */
  width: 280px;
  height: 360px;
  border-radius: 20px;
  background-size: cover;
}
.descriptionSection {
  width: 70%;
}

@media screen and (max-width: 720px) {
  .detailsContainer {
    flex-direction: column;
  }

  .imageSection {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  .image {
    /*
    Modified by - Ashish Dewangan on 22-01-2024
    Reason - To adjust size of image on responsive
    */
    /* width: 95%;
    height: 400px; */
    width: 87%;
    height: 360px;
    /*
    End of modification by - Ashish Dewangan on 22-01-2024
    Reason - To adjust size of image on responsive
    */
  }

  .descriptionSection {
    width: 100%;
  }
  /* Addition by Om Shrivastava on 28-06-2024
  Reason : Set the gap  */
  .title {
  padding-top: 6%;
  }
    /* End of addition by Om Shrivastava on 28-06-2024
  Reason : Set the gap  */
}
