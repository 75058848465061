/**
 * Created by - Ashish Dewangan on 24-01-2024
 * Reason - To have styling of jyot page
 */

/**
 Added by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/
.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}
/**
 End of addition by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading {
  margin: auto;
}
.currentTimeData {
  align-self: flex-end;
  font-size: 12px;
  padding-bottom: 0.5%;
}
.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);
  /* margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right); */
}

.section {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-around;

  /* border: 1px solid black; */
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}

@media screen and (max-width: 720px) {
  .section {
    flex-direction: column;
  }
  /* Added by - Ashlekh on 09-02-2024
  Reason - To display heading in center */
  .title{
    display: flex;
    flex-direction: row;
    position: relative;
  }
  /* End of code - Ashlekh on 09-02-2024
  Reason - To display heading in center */
  /* Addition by Om Shrivastava on 27-01-24
  Reason : Need to remove this div section */
  .currentTimeData {
    /* display: none; */
    margin-bottom: 1%;
    /* Added by - Ashlekh on 09-02-2024
    Reason - In mobile view heading will display in center and currentDate will display in left side */
    margin-left: auto;
    /* End of code - Ashlekh on 09-02-2024
    Reason - In mobile view heading will display in center and currentDate will display in left side */
  }
  .heading {
    margin-left: auto auto auto 3%;
    /* Added by - Ashlekh on 09-02-2024
    Reason - In mobile view heading must display in center */
    position: absolute;
    /* End of code addition by - Ashlekh on 09-02-2024
    Reason - In mobile view heading must display in center */
  }
  /* End of addition by Om Shrivastava on 27-01-24
  Reason : Need to remove this div section */
}
