/**
 * Created by - Ashish Dewangan on 18-01-2024
 * Reason - To have styling of contact us form
 */
.formContainer {
  width: 45%;

}
.inputHolder {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.label {
  font-size: var(--form-label-font-size);
  color: var(--form-label-font-color);
  font-weight: var(--form-label-font-weight);
  width: 20%;
}
.inputWithError {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.inputBox {
  /* Code changed by - Ashlekh on 12-03-2024
  Reason - To decrease gap  */
  /* padding: 5px 10px; */
  padding: 5px 5px;
  /* End of code - Ashlekh on 12-03-2024
  Reason - To decrease gap */
  outline: none;
}
.textArea {
  resize: none;
}
.buttonContainer {
  display: flex;
  padding-left: 20%;
}
.button {
  margin-left: 15px;
  padding: 5px 10px;
  background-color: brown;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 14px;
}
.button:hover {
  background-color: rgb(185, 75, 75);
}
.mapContainer {
  margin-top: 15px;
  width: 100%;
  height: 350px;
}
.validationMsgContainer{
  font-size: var(--page-validation-font-size);
  color: red;
  padding-left: var(--page-validation-padding-left);
}


@media screen and (max-width: 720px) {
  .formContainer {
    width: 100%;
  }
  .inputWithError {
    width: 75%;
  }
  .label {
    width: 25%;
  }
  .inputHolder {
    margin-left: 0px;
    margin-right: 0px;
  }
  .buttonContainer {
    padding: 0;
    justify-content: center;
  }
  .mapContainer {
    height: 200px;
  }
}
