/**
 * Created by - Ashish Dewangan on 13-01-2024
 * Reason - To have styling for video container
 */

.horizontalLine {
  height: var(--horizontal-line-thickness);
  background-color: var(--horizontal-line-background-color);
  border: none;
  margin-bottom: var(--horizontal-line-bottom-margin);
}
.subTitle {
  font-size: var(--page-subTitle-font-size);
  color: var(--page-subTitle-font-color);
  font-weight: var(--page-subTitle-font-weight);
  text-align: var(--page-subTitle-text-align);
}

@media screen and (max-width: 720px) {
}
