/**
Added by - Ashish Dewangan on 24-01-2024
Reason - To have styling of scanner container
*/
.contactUsDetailsContainer {
    width: 35%;
    /* Addition by Om Shrivastava on 04-04-24 
    Reason : Need to set the gap between line and content  */
    padding-left: 1%;
    /* End of Addition by Om Shrivastava on 04-04-24 
    Reason : Need to set the gap between line and content   */
    /* width: 20%; */
  
    /* border: 1px solid yellow; */
    display: flex;
    flex-direction: column;
  }
  .qrDataConatainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .dataContainer {
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    width: 70%;
    /* width: 80%; */
    /* align-items: center; */
    padding: 1%;
  }
  .row1 {
    width: 30%;
    font-weight: bold;
    /* border: 1px solid red; */
  }
  .row2 {
    width: 16%;
    /* border: 1px solid aqua; */
  }
  .row3 {
    width: 50%;
    /* Added by - Ashlekh on 10-02-2024
    Reason - for displaying large name in multiple lines */
    word-wrap: break-word;
    /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* End of code - Ashlekh on 10-02-2024
    Reason - for displaying large name in multiple lines */
  }
  .qrImage {
    /* Code changed by - Ashlekh on 09-02-2024
    Reason - To decrease width of scanner image */
    /* width: 20vw; */
    width: 16vw;
    /* End of code - Ashlekh on 09-02-2024
    Reason - To decrease width of scanner image */
    height: auto;
  }
  .contentTitle {
    font-size: var(--page-content-title-font-size) !important;
    color: var(--page-content-title-font-color) !important;
    font-weight: var(--page-content-title-font-weight) !important;
    text-align: var(--page-content-title-text-align) !important;
  }
  .paragraph {
    margin-bottom: var(--bottom-margin-between-contents);
    font-size: var(--page-content-font-size);
    color: var(--page-content-font-color);
    font-weight: var(--page-content-font-weight);
  }
  .noContentFoundText {
    margin-bottom: 15px;
    font-size: var(--page-content-font-size);
    color: var(--page-content-font-color);
    font-weight: var(--page-content-font-weight);
    text-align: left;
  }
  .paymentDetailContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @media screen and (max-width: 720px) {
    .contactUsDetailsContainer {
      width: 100%;
    }
    /*
    Added by - Ashish Dewangan on 22-01-2024
    Reason - To hide section on responsive
    */
    .hideOnResponsive {
      display: none;
    }
    /*
    End of code addition by - Ashish Dewangan on 22-01-2024
    Reason - To hide section on responsive
    */
    .qrImage {
      /* Modification and addition by Om Shrivastava on 07-02-24
      Reason : Need to fix the width */
      /* width: 50vw; */
      /* Code changed by - Ashlekh on 10-02-2024
      Reason - To decrease scanner image in mobile view */
      /* width: 70vw; */
      width: 65vw;
      /* End of code - Ashlekh on 10-02-2024
      Reason - To decrease scanner image in mobile view */
      /* End of modification and addition by Om Shrivastava on 07-02-24
      Reason : Need to fix the width */
    }
    .paymentDetailContainer{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  