/**
  * Created by - Ashlekh on 12-02-2024
  * Reason - For styling in temple trustee
  */

.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  margin-bottom: var(--page-title-margin-bottom);
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}

.mainContainer {
  margin-bottom: var(--bottom-margin-between-contents);
  display: flex;
  /* Code changed by - Ashlekh on 04-03-2024 */
  /* width: 62%; */
  width: 82%;
  /* End of code - Ashlekh on 04-03-2024 */
  margin-left: auto;
  margin-right: auto;
}
/* .imageContainer {
  width: 25%;
  height: 44vh;
  border: 1px solid black;
} */
.imageSubContainer {
  height: 335px;
  /* width: 335px; */
  width: 253px;
  border-radius: 5%;
  background-color: #f0d9dd;
  /* background-color: #e3dbe0; */
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
}
/* Added by - Ashlekh on 18-02-2024 */
.even{
  background-color: #f4e4dc;
}
.odd{
  background-color: #f4e4dc;
}
/* End of code - Ashlekh on 18-02-2024 */
.image {
  /* width: 335px; */
  margin-top: 12px;
  /* padding-left: 0.5px; */
  width: 90%;
  height: 70%;
  border-radius: 5%;
  /* border-radius: 50% 50%; */
  /* align-self: center; */
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.descriptionContainer {
  /* Code changed by - Ashlekh on 04-03-2024 */
  /* width: 70%; */
  width: 77%;
  /* End of code - Ashlekh on 04-03-2024 */
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid red; */
  /* Added by - Ashlekh on 17-02-2024
  Reason - To display container in center */
  display: flex;
  align-items: center;
  /* End of code - Ashlekh on 17-02-2024
  Reason - To display container in center */
  /* height: fit-content; */
}

.descriptionSubContainer {
  width: 99%;
  margin-left: auto;
  margin-right: auto;
   /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
  word-wrap: break-word;
  font-size: var(--page-content-font-size);
  /* border: 1px solid black; */
  /* height: auto; */
}
.templeTrusteeNameAndDesignation {
  padding-top: 4%;
  text-align: center;
  /* font-weight: bold; */
  min-height: 30px;
   /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
  word-wrap: break-word;
  padding-left: 12%;
  padding-right: 12%;
  /* margin-bottom: 0.1rem; */
  font-size: 14px;
}
.templeTrusteeName{
  font-weight: 900;
}
.templeTrusteeDesignation{

}
@media screen and (max-width: 720px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .descriptionContainer {
    width: 100%;
  }
  .descriptionSubContainer {
    width: 95%;
     /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    word-wrap: break-word;
    padding-top: 5%;
    /* border: 1px solid red; */
  }
  .templeTrusteeNameAndDesignation {
    padding-top: 4%;
    text-align: center;
    /* font-weight: bold; */
    min-height: 30px;
     /* Modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 11-04-24
    Reason : Need to remove this property */
    word-wrap: break-word;
    padding-left: 12%;
    padding-right: 12%;
    /* margin-bottom: 0.1rem; */
    /* font-size: 14px; */
  }
  /* .templeTrusteeName{
    font-weight: bolder !important;
  }
  .templeTrusteeDesignation{
    
  } */
}
