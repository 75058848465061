/**
  * Created by - Ashlekh on 19-01-2024
  * Reason - For styling in home page description
  */
.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);

  /* margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right); */
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To provide bottom margin globally
  **/
  margin-bottom: var(--bottom-margin-between-contents);
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To provide bottom margin globally
  **/
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}
