.godsPhotoContainer {
  /* height: 30vh;
    padding: 2% 4% 2%; */
  /* padding: 1% 4% 1%; */
  /* padding-top: 2%; */
  /* background-color: #fafafa; */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  /* border: 1px solid black; */
  justify-content: space-between;
  /* width: 100%; */
}

/**
Added by - Ashish Dewangan on 29-01-2024
Reason - To give bottom margin globally
**/
.container {
  margin-bottom: var(--bottom-margin-between-contents);
}
/**
End of code addition by - Ashish Dewangan on 29-01-2024
Reason - To give bottom margin globally
**/
.godsPhotoSubContainer {
  /* height: auto; */
  /* max-height: 100%; */

  /* min-width: 8.5%; */
  /* margin-right: .75%; */
  /* margin-left: 10px; */
  /* margin-top: 10px; */
  padding-top: 10px;
  /* box-sizing: border-box; */
  cursor: pointer;
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
  /* border: 2px solid black; */
  align-items: center;
  /* background-color: rgb(235, 18, 18); */
  /* 
    display: inline-block;
    vertical-align: top; */
}

.godImage {
  width: 110px;
  height: 110px;
  background-size: cover;
  border: 1px solid black;
  border-radius: 20px;
}

.textName {
  margin-top: 7px;
  text-align: center;
  font-weight: bold;
   /* Modification and addition by Om Shrivastava on 12-04-24 
    Reason : Need to remove this property */
    /* word-break: break-all; */
     /*End of modification and addition by Om Shrivastava on 12-04-24 
    Reason : Need to remove this property */
  word-wrap: break-word;
  /* word-spacing: normal; */
  font-size: 13px;
  margin-bottom: 0px !important;
  width: 80%;
  /* border: 1px solid black; */
}

.godImage:hover {
  box-shadow: rgba(250, 200, 152) 0px 5px 15px 10px;
}
/* Addition by Om Shrivastava on 11-02-24
Reason : Need to add the heading of khallari darsan */
.khallariHeading{
  font-weight: bold;
  padding: 1% 0% 0% 0.7%;
 
}
@media screen and (max-width: 720px) {
  .khallariHeading{
    font-weight: bold;
    padding: 2% 8% 0% 0%;
  }
  .container {
    margin-bottom: 20px;
  }
}
/* End of addition by Om Shrivastava on 11-02-24
Reason : Need to add the heading of khallari darsan */