/**
  * Created by - Ashlekh on 25-01-2023
  * Reason - For styling in volunteer page
  */

/**
 Added by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/
.pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}
/**
 End of addition by - Ashish Dewangan on 01-02-2024
 Reason - To provide background image
 **/

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  /* padding-bottom: 10px; */
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
  margin-bottom: var(--page-title-margin-bottom);
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
}

.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  /* text-align: var(--page-content-text-align); */
  text-align: center;
  margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right);
}

.subTitle {
  /* font-size: var(--page-title-font-size);
    color: var(--page-title-font-color);
    font-weight: var(--page-title-font-weight);
    text-align: var(--page-title-text-align); */
  text-align: center;
  /* font-weight: bold; */
  /* font-size: 20px; */
  padding-bottom: 10px;
  font-size: var(--page-content-font-size);
}
/* .content {
  text-align: center;
  font-size: 20px;
} */
