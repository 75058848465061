/**
 * Created by - Ashish Dewangan on 18-01-2024
 * Reason - To have styling of contact us page
 */

/**
 Added by - Ashish Dewangan on 31-01-2024
 Reason - To provide background image
 **/
 .pageFrame {
  background-image: var(--page-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.coloredBackground {
  background-color: var(--page-background-color);
}
/**
 End of addition by - Ashish Dewangan on 31-01-2024
 Reason - To provide background image
 **/

.pageContainer {
  margin-left: var(--page-margin-left);
  margin-right: var(--page-margin-right);
  margin-top: var(--page-margin-top);
  margin-bottom: var(--page-margin-bottom);
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);
  min-height: var(--page-min-height);
}

.title {
  font-size: var(--page-title-font-size);
  color: var(--page-title-font-color);
  font-weight: var(--page-title-font-weight);
  text-align: var(--page-title-text-align);
  /**
  Added by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
  margin-bottom: var(--page-title-margin-bottom);
  /**
  End of code addition by - Ashish Dewangan on 29-01-2024
  Reason - To have bottom margin for title
  **/
}

.content {
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);
  margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right);
}
/* Addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
.loaderDiv{
  font-size: var(--page-content-font-size);
  color: var(--page-content-font-color);
  font-weight: var(--page-content-font-weight);
  text-align: var(--page-content-text-align);
  margin-left: var(--page-content-margin-left);
  margin-right: var(--page-content-margin-right);
}
/* End of addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */

.section {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--bottom-margin-between-contents);
}

.noContentFoundText {
  margin-top: var(--page-content-not-found-margin-top);
  margin-bottom: var(--page-content-not-found-margin-bottom);
  font-size: var(--page-content-not-found-font-size);
  color: var(--page-content-not-fount-font-color);
  font-weight: var(--page-content-not-found-font-weight);
  text-align: var(--page-content-not-found-text-align);
}
/**
Added by - Ashish Dewangan on 31-01-2024
Reason - Resized map
**/
.map {
  width: 100%;
  height: 270px;
}
/**
End of addition by - Ashish Dewangan on 31-01-2024
Reason - Resized map
**/

@media screen and (max-width: 720px) {
  .section {
    flex-direction: column;
  }
  /**
Added by - Ashish Dewangan on 31-01-2024
Reason - Resized map
**/
  .map {
    width: 100%;
  }
  /**
End of addition by - Ashish Dewangan on 31-01-2024
Reason - Resized map
**/
/* Addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
.loaderDiv{
  min-height: 85vh;
}
/* End of addition by Om Shrivastava on 04-04-24 
Reason : Need to set min height in mobile view  */
}
